var firebaseconfig = {
    apiKey: "AIzaSyAa-6cHN65VYpgwEyZDNwnBPNUGNBffMEU",
    authDomain: "aclayviento.firebaseapp.com",
    databaseURL: "https://aclayviento.firebaseio.com",
    projectId: "aclayviento",
    storageBucket: "aclayviento.appspot.com",
    messagingSenderId: "33421184831",
    appId: "1:33421184831:web:8dd51cd6c9affd45094d8a"
};
//anclayviento as
export default firebaseconfig;
