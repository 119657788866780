import '../css/app.scss';
import '../css/page.scss';

import 'swiper/swiper-bundle.css';

import $ from 'jquery'
window.jQuery = $;
global.$ = global.jQuery = $;

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import firebaseConfig from "./firebaseconfig";


import Swiper from 'swiper/bundle';

/*var firebaseConfig = {
    apiKey: "AIzaSyBfrwhVdJZkrnWOBZLx5E2mIdiqG1Abbps",
    authDomain: "miveci.firebaseapp.com",
    databaseURL: "https://miveci.firebaseio.com",
    projectId: "miveci",
    storageBucket: "miveci.appspot.com",
    messagingSenderId: "942307173812",
    appId: "1:942307173812:web:af325e3646581c0c0c7ee1"
};*/
/*
var firebaseConfig = {
    apiKey: "AIzaSyCfFCh-ta4IA2xO-Ck3ayzaeoGvjtWDcfA",
    authDomain: "mimosa-iridian.firebaseapp.com",
    databaseURL: "https://mimosa-iridian.firebaseio.com",
    projectId: "mimosa-iridian",
    storageBucket: "mimosa-iridian.appspot.com",
    messagingSenderId: "556085628347",
    appId: "1:556085628347:web:590245308a2742c747889e"
};
*/
firebase.initializeApp(firebaseConfig);
if(firebaseConfig.measurementId)
    firebase.analytics();

$(function () {
    console.log('ancla');



    

    $('#form').submit(function (e) {
        e.preventDefault();
    });
    $('#anterior').change(function () {
        if($(this).val() != 0){
            window.location = $(this).val();
        }
    })
    $('#cerrar_sesion').click(function (e) {
        console.log("cerrar sesion");
        e.preventDefault();
        firebase.auth().signOut();
        window.location = '/logout';
    });
    $(".usuario-tool").click(function(){
        if($(this).hasClass("menu-open")){
            $(this).removeClass("menu-open");
        } else {
            $(this).addClass("menu-open");
        }
    });
    $(".menu-mobile").click(function(){
        if($(this).hasClass("menu-open-dos")){
            $(this).removeClass("menu-open-dos");
            $(".menue").removeClass("menu-open-dos");
        } else {
            $(this).addClass("menu-open-dos");
            $(".menue").addClass("menu-open-dos");
        }
    });
});

function setLatLng(latLng) {
    $('#lat').val(latLng.lat());
    $('#lng').val(latLng.lng());
}

/**
 * Handles the sign up button press.
 */
function handleSignUp() {
    showLoader();
    $('#errores').hide();
    var nombre = document.getElementById('nombre').value;
    var apellido = document.getElementById('apellido').value;
    var email = document.getElementById('email').value;
    var password = document.getElementById('password').value;
    var password_second = document.getElementById('password_second').value;
    if (nombre.length < 2) {
        setError('Ingresa tu nombre');
        return;
    }
    if (nombre.length < 3) {
        setError('Ingresa tu apellido');
        return;
    }
    if (email.length < 4) {
        setError('Ingresa tu email');
        return;
    }
    if (password.length < 4) {
        setError('Ingresa tu contraseña');
        return;
    }
    if (password != password_second) {
        setError('Las contraseñas deben coincidir');
        return;
    }
    fbq('track', 'CompleteRegistration');
    firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/weak-password') {
            setError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setError(errorMessage);
        }
        console.log(error);
    });
}


function handleSignUpdireccion() {
    $('#errores').hide();
    var flag=true;
    $('#formdirnueva').find(':invalid').each(function() {
        var message="";
        if(this.validity.valueMissing){
            message= "Complete los campos";
        } else if(this.validity.patternMismatch){
            message= "Ingrese un valor adecuado";
        }else{
            message=this.validationMessage;
        }
        setError(message);
        flag=false;
    });
    /*var email = document.getElementById('email').value;
    console.log(email);
    if (email.length < 4) {
        alert('Por favor ingresa un correo');
        return;
    }*/
    // Sign in with email and pass.
    // [START createwithemail]
    var email = document.getElementById('email').value;
    if (flag) {
        fbq('track', 'CompleteRegistration');
        firebase.auth().createUserWithEmailAndPassword(email, 'anclanewuser')
            .then(function (result) {
                $('#formdirnueva').submit();
            }).catch(function (error) {
            // Handle error.

            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // [START_EXCLUDE]
            if (errorCode == 'auth/weak-password') {
                alert('The password is too weak.');
            } else {
                setError(errorMessage);
            }

            console.log(error);
            // [END_EXCLUDE]
        });
    }
    // [END createwithemail]
}


/**
 * Handles the sign in button press.
 */
function toggleSignIn() {
    showLoader();
    $('#errores').hide();
    if (firebase.auth().currentUser) {
    } else {
        var email = document.getElementById('email').value;
        var password = document.getElementById('password').value;
        if (email.length < 4) {
            setError('Ingresa tu email');
            return;
        }
        if (password.length < 4) {
            setError('Ingresa tu contraseña');
            return;
        }
        firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') {
                setError('Contraseña Inválida');
            } else {
                setError(errorMessage);
            }
            console.log(error);
            document.getElementById('quickstart-sign-in').disabled = false;
        });
    }
    document.getElementById('quickstart-sign-in').disabled = true;
}

function sendPasswordReset() {
    showLoader();
    $('#errores').hide();
    var email = document.getElementById('email').value;
    // [START sendpasswordemail]
    firebase.auth().sendPasswordResetEmail(email).then(function() {
        $('#success').show();
    }).catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/invalid-email') {
            setError('Email inválido');
        } else if (errorCode == 'auth/user-not-found') {
            setError('Usuario no encontrado');
        }
        console.log(error);
        // [END_EXCLUDE]
    });
    // [END sendpasswordemail];
}

function setError(mensaje) {
    $('.loader-wrapper').hide();
    $('#errores').show().html(mensaje);
    window.scrollTo(0, 0);
}

function showLoader() {
    $('.loader-wrapper').show();
}


function initApp() {
    // Listening for auth state changes.
    // [START authstatelistener]
    firebase.auth().onAuthStateChanged(function(user) {
        // [START_EXCLUDE silent]
        //document.getElementById('quickstart-verify-email').disabled = true;
        // [END_EXCLUDE]
        if (user) {
            // User is signed in.
            var displayName = user.displayName;
            var email = user.email;
            var emailVerified = user.emailVerified;
            var photoURL = user.photoURL;
            var isAnonymous = user.isAnonymous;
            var uid = user.uid;
            var providerData = user.providerData;
            var user_up = {};
            if($('#nombre').length > 0){
                var nombre = document.getElementById('nombre').value;
                user_up.nombre = nombre;
            }
            if($('#apellido').length > 0){
                var apellido = document.getElementById('apellido').value;
                user_up.apellido = apellido;
            }
            if($('#telefono').length > 0){
                user_up.telefono = $('#telefono').val();
            }
            user_up.email = email;
            user_up.uid = uid;
            user_up.providerData = providerData;
            console.log(user);

            $.ajax({
                type: "POST",
                url: "/api/auth/register",
                // The key needs to match your method's input parameter (case-sensitive).
                data: JSON.stringify(user_up),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function(data){
                    console.log(data);
                    window.location = data.url;
                },
                failure: function(errMsg) {
                    alert(errMsg);
                }
            });
        } else {
        }
    });

    if($('#quickstart-sign-in').length > 0)
        document.getElementById('quickstart-sign-in').addEventListener('click', toggleSignIn, false);
    if($('#quickstart-sign-up').length > 0)
        document.getElementById('quickstart-sign-up').addEventListener('click', handleSignUp, false);
    //document.getElementById('quickstart-verify-email').addEventListener('click', sendEmailVerification, false);

    if($('#quickstart-password-reset').length > 0){
        document.getElementById('quickstart-password-reset').addEventListener('click', sendPasswordReset, false);
    }
}

window.onload = function() {
    console.log('ini fire');
       if(window.location.href.includes('crear-cuenta') || window.location.href.includes('ingresar') || window.location.href.includes('olvide')) {
        initApp();
    }
    if(window.location.href.includes('misdirecciones')){
        document.getElementById('quickstart-dir-nueva').addEventListener('click', handleSignUpdireccion, false);

    }
};
